import React from 'react'

export enum NetworkID {
  Mainnet = 56,
  Testnet = 43113,
}

export interface BondAddresses {
  reserveAddress: string
  bondAddress: string
}

export interface NetworkAddresses {
  [NetworkID.Mainnet]: BondAddresses
  [NetworkID.Testnet]: BondAddresses
}

export interface Available {
  [NetworkID.Mainnet]?: boolean
  [NetworkID.Testnet]?: boolean
}
