import React from 'react'
import { Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './LaunchButton.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const LaunchButton = () => {
  console.log('process.env', process.env)
  const mainapp = process.env.REACT_APP_MAINAPP ?? ''
  console.log('mainapp', mainapp)
  // ANCHOR data
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  // ANCHOR methods
  const enter = async () => {
    window.location.assign('https://main.powerswap.fi/swap')
    // navigate(mainapp)
  }
  return (
    <Button variant='contained' color='primary' className='connect-button' onClick={enter}>
      {t('actions.LAUNCH')} <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
    </Button>
  )
}

export default LaunchButton
