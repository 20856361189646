import * as React from 'react'
import { Box, Toolbar, AppBar, IconButton, Grid, Link } from '@mui/material'
import LaunchButton from '../LaunchButton/LaunchButton'
import './Header.scss'
import I18n from '../I18n/I18n'
import Logo from '../../assets/icons/logo.png'
import LogoMobile from '../../assets/icons/logo-mobile.png'
import './Header.scss'

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }} className='header'>
      <AppBar position='static'>
        <Toolbar>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Link href='/'>
              <IconButton>
                <div className='logo'></div>
                <div className='logo-mobile'></div>
              </IconButton>
            </Link>
            <div className='header-right'>
              {/* <I18n /> */}
              <LaunchButton></LaunchButton>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
