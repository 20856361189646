import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IBaseAsyncThunk, IBaseFreeAsyncThunk } from './interfaces'
import { ethers } from 'ethers'
import { addresses } from '../constants'
import abi from '../abi/be.json'

export const referralCode = createAsyncThunk(
  'app/referralCode',
  async ({ networkID, address }: IBaseFreeAsyncThunk, { dispatch }): Promise<string> => {
    console.log('getting referral code')
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_SELF_HOSTED_NODE_MAIN,
    )
    const contract = new ethers.Contract(addresses[networkID].CONTRACT_ADDRESS, abi, provider)
    const res = await contract.checkCode(address)
    return res
  },
)

interface AppState {
  myAddress: string
  loading: boolean
  referralCode: string
  alertMsg: string
  openAlert: boolean
  alertType: string
}

interface AlertConfig {
  alertMsg: string
  alertType: string
}

const initialState = { myAddress: '', loading: false, referralCode: '' } as AppState

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeMyAddress(state, action: PayloadAction<string>) {
      state.myAddress = action.payload
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    alertMsg(state, action: PayloadAction<string>) {
      state.alertMsg = action.payload
    },
    openAlert(state, action: PayloadAction<boolean>) {
      state.openAlert = action.payload
    },
    alertType(state, action: PayloadAction<string>) {
      state.alertType = action.payload
    },
    alert(state, action: PayloadAction<AlertConfig>) {
      state.alertMsg = action.payload.alertMsg
      state.openAlert = true
      state.alertType = action.payload.alertType
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(referralCode.pending, (state) => {
        state.loading = true
      })
      .addCase(referralCode.fulfilled, (state, action) => {
        state.referralCode = action.payload
        state.loading = false
      })
      .addCase(referralCode.rejected, (state, { error }) => {
        state.loading = false
        console.error(error.name, error.message, error.stack)
      })
  },
})

export const { changeMyAddress, loading, alertMsg, openAlert, alertType, alert } = appSlice.actions
export default appSlice.reducer
