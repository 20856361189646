import React, { useState, ReactElement, useContext, useEffect, useMemo, useCallback } from 'react'
import Web3Modal from 'web3modal'
import { StaticJsonRpcProvider, JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { IFrameEthereumProvider } from '@ledgerhq/iframe-provider'
import { BASE_BSC_SCAN_URLS } from '../constants'
import { confirmAlert } from 'react-confirm-alert'
import SwitchDialog from '../components/SwitchDialog'
import { NetworkID } from 'src/lib/Bond'

/**
 * kept as function to mimic `getMainnetURI()`
 * @returns string
 */
function getTestnetURI() {
  return process.env.REACT_APP_SELF_HOSTED_NODE_MAIN || ''
}

/**
 * determine if in IFrame for Ledger Live
 */
function isIframe() {
  return window.location !== window.parent.location
}

// const ALL_URIs = NodeHelper.getNodesUris();

/**
 * "intelligently" loadbalances production API Keys
 * @returns string
 */
function getMainnetURI(): string {
  return process.env.REACT_APP_SELF_HOSTED_NODE_MAIN || ''
}

/*
  Types
*/
type onChainProvider = {
  connect: () => any
  disconnect: () => void
  provider: JsonRpcProvider
  address: string
  connected: boolean
  web3Modal: Web3Modal
  web3?: object
  chainID: NetworkID
  hasCachedProvider?: any
  uri?: string
}

export type Web3ContextData = {
  onChainProvider: onChainProvider
} | null

const Web3Context = React.createContext<Web3ContextData>(null)

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context)
  if (!web3Context) {
    throw new Error(
      'useWeb3Context() can only be used inside of <Web3ContextProvider />, ' +
        'please declare it at a higher level.',
    )
  }
  const { onChainProvider } = web3Context
  return useMemo(() => {
    return { ...onChainProvider }
  }, [web3Context])
}

export const useAddress = () => {
  const { address } = useWeb3Context()
  return address
}

const ExpectedChainID = Number(process.env.REACT_APP_CHAIN_ID)

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false)
  // NOTE (appleseed): if you are testing on rinkeby you need to set chainId === 4 as the default for non-connected wallet testing...
  // ... you also need to set getTestnetURI() as the default uri state below

  const [chainID, setChainID] = useState(ExpectedChainID) // setting default networkID
  const [address, setAddress] = useState('')

  const [uri, setUri] = useState(
    ExpectedChainID == NetworkID.Mainnet ? getMainnetURI() : getTestnetURI(),
  )

  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(uri))

  const [web3Modal, setWeb3Modal] = useState<Web3Modal>(
    new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [NetworkID.Mainnet]: getMainnetURI(),
              [NetworkID.Testnet]: getTestnetURI(),
            },
          },
        },
      },
    }),
  )

  const hasCachedProvider = (): boolean => {
    if (!web3Modal) return false
    if (!web3Modal.cachedProvider) return false
    return true
  }

  // NOTE (appleseed): none of these listeners are needed for Backend API Providers
  // ... so I changed these listeners so that they only apply to walletProviders, eliminating
  // ... polling to the backend providers for network changes
  const _initListeners = useCallback(
    (rawProvider: any) => {
      if (!rawProvider.on) {
        return
      }
      rawProvider.on('accountsChanged', async (accounts: string[]) => {
        setTimeout(() => window.location.reload(), 1)
      })

      rawProvider.on('chainChanged', async (chain: number) => {
        _checkNetwork(chain)
        setTimeout(() => window.location.reload(), 1)
      })

      rawProvider.on('network', (_newNetwork: any, oldNetwork: any) => {
        if (!oldNetwork) return
        window.location.reload()
      })
    },
    [provider],
  )

  /**
   * throws an error if networkID is not 1 (mainnet) or 4 (rinkeby)
   */
  const _checkNetwork = (otherChainID: number): boolean => {
    if (chainID !== otherChainID) {
      console.warn('You are switching networks')
      if (otherChainID === ExpectedChainID) {
        setChainID(otherChainID)
        otherChainID === NetworkID.Mainnet ? setUri(getMainnetURI()) : setUri(getTestnetURI())
        return true
      }
      return false
    }
    return true
  }

  const setNetwork = useCallback(async () => {
    try {
      const params = {
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${ExpectedChainID.toString(16)}`,
            chainName: 'Binance Smart Chain Mainnet',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18,
            },
            rpcUrls: [ExpectedChainID == NetworkID.Mainnet ? getMainnetURI() : getTestnetURI()],
            blockExplorerUrls: [`${BASE_BSC_SCAN_URLS[ExpectedChainID]}/`],
          },
        ],
      }
      console.log('params', params)
      await window.ethereum.request(params)

      window.location.reload()
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
    }
  }, [])
  const [open, setOpen] = useState(false)
  // connect - only runs for WalletProviders
  const connect = useCallback(async () => {
    // handling Ledger Live;
    let rawProvider
    if (isIframe()) {
      rawProvider = new IFrameEthereumProvider()
    } else {
      rawProvider = await web3Modal.connect()
    }

    // new _initListeners implementation matches Web3Modal Docs
    // ... see here: https://github.com/Web3Modal/web3modal/blob/2ff929d0e99df5edf6bb9e88cff338ba6d8a3991/example/src/App.tsx#L185
    _initListeners(rawProvider)
    const connectedProvider = new Web3Provider(rawProvider, 'any')
    const chainId = await connectedProvider.getNetwork().then((network) => network.chainId)
    const connectedAddress = await connectedProvider.getSigner().getAddress()
    const validNetwork = _checkNetwork(chainId)
    if (!validNetwork) {
      // console.error("Wrong network, please switch to mainnet");
      setTimeout(() => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return <SwitchDialog setNetwork={setNetwork} setOpen={onClose} />
          },
        })
      }, 100)
      return Promise.reject('Wrong network, please switch to mainnet')
    }
    // Save everything after we've validated the right network.
    // Eventually we'll be fine without doing network validations.
    setAddress(connectedAddress)
    setProvider(connectedProvider)

    // Keep this at the bottom of the method, to ensure any repaints have the data we need
    setConnected(true)

    return [connectedProvider, connectedAddress]
  }, [provider, web3Modal, connected])

  const disconnect = useCallback(async () => {
    console.log('disconnecting')
    web3Modal.clearCachedProvider()
    setConnected(false)

    setTimeout(() => {
      window.location.reload()
    }, 1)
  }, [provider, web3Modal, connected])

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      uri,
    }),
    [connect, disconnect, hasCachedProvider, provider, connected, address, chainID, web3Modal, uri],
  )

  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>
}
