import React from 'react'
import { ReactComponent as TgIcon } from '../../assets/icons/tg.svg'
import { ReactComponent as MIcon } from '../../assets/icons/m.svg'
import { ReactComponent as DcIcon } from '../../assets/icons/dc.svg'
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg'
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg'
import { Link } from '@mui/material'
import './ContactUs.scss'
import Popover from '@mui/material/Popover'

export default function ContactUs() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div className='contact-us'>
      <span onClick={handleClick}>
        <TgIcon aria-describedby={id} />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ul className='pop-list'>
          <li>
            <Link
              href='https://t.me/PowerSwapOfficial'
              color='inherit'
              underline='none'
              target='_blank'
            >
              English
            </Link>
          </li>
          <li>
            <Link href='https://t.me/Powerswap_cn' color='inherit' underline='none' target='_blank'>
              中文
            </Link>
          </li>
        </ul>
      </Popover>

      <Link href='https://discord.gg/QcXZy3SaxJ' color='inherit' underline='none' target='_blank'>
        <DcIcon />
      </Link>
      <Link
        href='https://medium.com/@PowerSwapOfficial'
        color='inherit'
        underline='none'
        target='_blank'
      >
        <MIcon />
      </Link>
      <Link
        href='https://twitter.com/PowerSwapPOW'
        color='inherit'
        underline='none'
        target='_blank'
      >
        <TwitterIcon />
      </Link>
    </div>
  )
}
