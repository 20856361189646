import { NetworkID } from './lib/Bond'

interface IAddresses {
  [key: number]: { [key: string]: string }
}

export const addresses: IAddresses = {
  [NetworkID.Testnet]: {
    CONTRACT_ADDRESS: '0x8FF80f04f5Af01F582E723fD1892F81e745631Ae',
    AIRDROP1_ADDRESS: '0x1E8B0573DfD5E67533c4ce10AAf5570d05E15c55',
    BUSD: '0x339102fcb0c6a1802de0268fb9461710e95ed244',
    PRESALE: '0xa359847D0132EaA2431BAB7281576C010b74B37d',
    TTOKEN: '0xC2A08c77A7b755E1905EB26CA0Fa5C2812911fC3',
    AIRDROP2_ADDRESS: '0xa4ed8185C15C49E324419Ac7c6F9ef27CBFb9e27',
  },
  [NetworkID.Mainnet]: {
    CONTRACT_ADDRESS: '0xB59c06fb47257cdFD40DF9a64e7C4974Fd28e2aB',
    AIRDROP1_ADDRESS: '0x60c82c9e8a78C24347279DEc14c023C823596600',
    BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    PRESALE: '0x379c32AbC93214514BcF42a403A1e1cf83A16dA3',
    TTOKEN: '0x0Bae728a5753462B87Db8dc7C84FD6042b6CEF86',
    AIRDROP2_ADDRESS: '0x7505C5D1b27efCe09A057946850ff0C4daFa1b82',
  },
}
interface IScanURLs {
  [key: number]: string
}
export const BASE_BSC_SCAN_URLS: IScanURLs = {
  [NetworkID.Mainnet]: 'https://bscscan.com',
  [NetworkID.Testnet]: 'https://testnet.bscscan.com',
}
