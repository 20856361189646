/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function SwitchDialog({ setNetwork, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }
  const changeNetwork = () => {
    setOpen(false)
    setNetwork()
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Switch networks</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Please connect to correct network.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={changeNetwork}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}
