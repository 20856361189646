import React from 'react'
// ANCHOR react
import AppRouter from './routes'
// ANCHOR store imports
import store from 'src/store'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import BeAlert from 'src/components/BeAlert/BeAlert'
import { AlertColor } from '@mui/material'

export default function App() {
  const myAddress = useSelector((state: any) => state.app.myAddress)

  const openBind = useSelector((state: any) => state.app.openBind)
  const alertMsg = useSelector((state: any) => state.app.alertMsg)
  const openAlert = useSelector((state: any) => state.app.openAlert)
  const alertType = useSelector((state: any) => state.app.alertType)

  const loading = useSelector((state: any) => state.app.loading)
  const handleClose = () => {
    // setOpen(false)
  }
  const handleToggle = () => {
    // setOpen(!open)
  }
  return (
    <div>
      <BeAlert
        text={alertMsg}
        open={openAlert}
        type={alertType as AlertColor}
        handleClose={handleClose}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <AppRouter />
    </div>
  )
}
