import React, { useEffect } from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import store from 'src/store'
import { openAlert } from 'src/slices/AppSlice'
interface Props {
  text: string
  open: boolean
  type: AlertColor
  handleClose: () => void
}

export default function BeAlert(props: Props) {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    setOpen(props.open)
    if (props.open === true) {
      setTimeout(() => {
        store.dispatch(openAlert(false))
      }, 3000)
    }
  }, [props.open])

  useEffect(() => {
    if (!open) {
      // must sync open state in store cause auto close
      // store.dispatch(openAlert(false))
    }
  }, [open])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity={props.type} onClose={handleClose} className='be-tip'>
        {props.text}
      </Alert>
    </Snackbar>
  )
}
