import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import './Home.scss'
import { Button } from '@mui/material'
import { ReactComponent as VideoIcon } from '../../assets/icons/video.svg'
import trophy from '../../assets/images/trophy.png'
import ball from '../../assets/images/ball.gif'
import foot from '../../assets/images/foot.png'
import SupplyIcon from '../../assets/icons/supply.png'
import BurnIcon from '../../assets/icons/burn.png'
import UtilityIcon from '../../assets/icons/utility.png'
import SlumpIcon from '../../assets/icons/slump.png'
import TnxTaxIcon from '../../assets/icons/tnx-tax.png'
import WhaleTaxIcon from '../../assets/icons/whale-tax.png'
import platformImg from '../../assets/images/platform.png'
import globalImg from '../../assets/images/global.png'
import peopleImg from '../../assets/images/people.png'
import footballcoin from '../../assets/images/footballcoin.png'
import nftcard from '../../assets/images/nftcard.png'
import secureImg from '../../assets/images/secure.png'
import stableImg from '../../assets/images/stable.png'
import One_stop_Defi from '../../assets/images/One_stop_Defi.png'
import Build_ETH_POW from '../../assets/images/Build_ETH_POW.png'
import faqImg from '../../assets/images/faq.png'
import pinkImg from 'src/assets/icons/pink.png'
import { ReactComponent as TgIcon } from '../../assets/icons/tg.svg'
import { ReactComponent as MIcon } from '../../assets/icons/m.svg'
import { ReactComponent as DcIcon } from '../../assets/icons/dc.svg'
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg'
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg'

import { ReactComponent as BetterListIcon } from '../../assets/icons/betterListIcon.svg'
import { ReactComponent as RoadConIcon } from '../../assets/icons/roadConIcon.svg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Logo from '../../assets/icons/logo.png'
import Cointe from '../../assets/icons/cointe.png'
import Yahoo from '../../assets/icons/yahoo.png'
import Radar from '../../assets/icons/radar.png'
import Rugdoc from '../../assets/icons/rugdoc-main-logo.png'
import Bsc from '../../assets/icons/bsc.png'
import LogoMobile from '../../assets/icons/logo-mobile.png'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import CountDown from 'src/components/CountDown/CountDown'
import ContactUs from 'src/components/ContactUs/ContactUs'

function Home() {
  const [activeFaq, setActiveFaq] = useState(0)
  const [tab, setTab] = useState('TRAIN')
  const { t, i18n } = useTranslation()

  return (
    <div className='App'>
      <Header></Header>
      <div className='main'>
        <div className='intro'>
          <p className='chain'>{/* <b>{t('home.BuildOn')}</b> */}</p>
          <div className='desc'>
            <h1>
              <span className='emphasize'>{t('home.TheFirst.0')}</span>
            </h1>
            <h4>{t('home.SupportOur')}</h4>
            <p className='desc--text'>{t('home.BetterTogetheris')}</p>
          </div>

          <div className='learn-more-btns'>
            <Link href='https://docs.powerswap.fi/' target='_blank'>
              <div className='doc-btn'>{t('actions.learnMore')}</div>
            </Link>
            {/* <Link href='https://youtube.com/watch?v=n3CxksfU2KU' target='_blank'>
              <div className='video outlined-btn'>
                <span className='video-icon'>
                  <VideoIcon />
                </span>
                {t('actions.YTVideo')}
              </div>
            </Link> */}
          </div>
          {/* <div className='count-down home-count-down'>
            <img src={trophy} alt='trophy' className='trophy' />
            <CountDown
              width='450px'
              cellWidth='50px'
              endDate='2022-8-29 12:00'
              className='count-down--num__pc'
            ></CountDown>
            <span className='count-down-ido-starts'>{t('home.idoStarts')}</span>
            <CountDown
              width='322px'
              cellWidth='50px'
              endDate='2022-8-29 12:00'
              className='count-down--num__mobile'
            ></CountDown>
            <img src={foot} alt='foot' className='foot' />
          </div> */}
          <div className='statistic'>
            <div className='staticstic-box'>
              <div className='staticstic-box--title'>-- {t('words.users')}</div>
            </div>
            <div className='staticstic-box'>
              <div className='staticstic-box--title'>-- {t('words.trades')}</div>
            </div>
            <div className='staticstic-box'>
              <div className='staticstic-box--title'>-- {t('words.staked')}</div>
            </div>
          </div>
          <div className='model'>
            <div className='model--tab-blocks'>
              <div className='model--tab-block gray-colored-border'>
                <img src={stableImg} />
                <h3 className='model--tab-block-tit'>{t('words.Stable')}</h3>
                <ul>
                  <li>{t('home.modelTabs.spe.0')}</li>
                </ul>
                <span className='divider-horizon'></span>
              </div>
              <div className='model--tab-block gray-colored-border'>
                <img src={secureImg} />
                <h3 className='model--tab-block-tit'>{t('words.Secure')}</h3>
                <ul>
                  <li>{t('home.modelTabs.spe.1')}</li>
                </ul>
                <span className='divider-horizon'></span>
              </div>
              <div className='model--tab-block gray-colored-border'>
                <img src={One_stop_Defi} />
                <h3 className='model--tab-block-tit'>{t('words.BuildETH')}</h3>
                <ul>
                  <li>{t('home.modelTabs.spe.2')}</li>
                </ul>
                <span className='divider-horizon'></span>
              </div>
              {/* secound row */}
              <div className='model--tab-block gray-colored-border'>
                <img src={Build_ETH_POW} />
                <h3 className='model--tab-block-tit'>{t('words.osDefi')}</h3>
                <ul>
                  <li>{t('home.modelTabs.spe.3')}</li>
                </ul>
                <span className='divider-horizon'></span>
              </div>
            </div>
          </div>
          <div className='promo'>
            <div className='promo-block'>
              <div className='promo-block--text'>
                <h3>{t('home.Earn2')}</h3>
                <ul>
                  <li>{t('home.trainIs')}</li>
                </ul>
              </div>
              <div className='promo-block--img'>
                <img src={platformImg} alt='plateformImg' />
              </div>
            </div>
            <div className='promo-block'>
              <div className='promo-block--img'>
                <img src={globalImg} alt='globalImg' />
              </div>
              <div className='promo-block--text'>
                <h3>{t('home.SkillWill')}</h3>
                <ul>
                  <li>{t('home.SkillWillbe')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className='footer-left'>
          <div className='logo'></div>
          <div className='logo-mobile'></div>
          <ContactUs></ContactUs>
        </div>
        <div className='footer-right'>
          <dl>
            <dt>{t('words.Resources')}</dt>

            <dd>
              <Link
                href='https://docs.powerswap.fi/'
                color='inherit'
                underline='none'
                target='_blank'
              >
                {t('words.Docs')}
              </Link>
            </dd>

            <dd>
              <Link
                href='https://www.interfi.network/audits#PowerSwap'
                color='inherit'
                underline='none'
                target='_blank'
              >
                <span>{t('words.AuditReport')}</span>
              </Link>
            </dd>
            <dd>
              <Link
                href='https://www.interfi.network/audits#PowerSwap'
                color='inherit'
                underline='none'
                target='_blank'
              >
                <span>Audit Report Farm</span>
              </Link>
            </dd>
          </dl>
          <dl>
            <dt>{t('words.Contactus')}</dt>
            <dd>
              <Link href='mailto:kbvczi@gmail.com'>{t('words.CareerOpportunity')}</Link>
            </dd>
            <dd>
              <Link href='mailto:kbvczi@gmail.com'>{t('words.BusinessCooperation')}</Link>
            </dd>
          </dl>
        </div>
      </footer>
    </div>
  )
}

export default Home
