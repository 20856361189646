import { createTheme } from '@mui/material/styles'
const darkTheme = {
  fontColor: '#fff',
  color: '#797878',
  gold: '#F8CC82',
  gray: '#A3A3A3',
  yellow: 'rgba(255, 165, 67, 1)',
  yellowHover: 'rgba(255, 165, 67, 0.5)',
  lightGray: 'rgba(246, 246, 246, 1)',
  blueish_gray: '#768299',
  textHighlightColor: '#93AEBC', // "#F4D092",
  backgroundColor: '#AFCDE9',
  // background:
  //   "radial-gradient(circle at top left, rgba(189, 230, 250, 0.64), rgba(255, 255, 255, 1) 50%), radial-gradient(circle at top right, rgba(255, 229, 206, 1), rgba(255, 255, 255, 1) 50%)",
  // background: "linear-gradient(180deg, #AFCDE9 1%, #F7FBE7 100%)",

  background: 'linear-gradient(136deg, rgba(95, 125, 154, 0.17) 0%, rgba(165, 115, 39, 0.17) 100%)',
  paperBg: 'rgba(255, 255, 255, 1)',
  modalBg: '#FAFAFAEF',
  popoverBg: 'rgba(255, 255, 255, 0.95)',
  backdropBg: 'linear-gradient(180deg, #F1FAFF 0%, #F9FAFC 100%)',
  largeTextColor: '#759AAE',
  activeLinkColor: '#222222',
  activeLinkSvgColor:
    'invert(64%) sepia(11%) saturate(934%) hue-rotate(157deg) brightness(90%) contrast(86%)',
  primaryButtonBG: 'linear-gradient(90deg, #3C00FF 0%, #EC6EFF 100%)',
  primaryButtonHoverBG: 'linear-gradient(45deg, #3C00FF 0%, #EC6EFF 100%)',
  containedSecondaryButtonBG: 'rgba(90, 187, 255, 1)',
  // these need fixing
  primaryButtonHoverColor: '#333333',
  secondaryButtonHoverBG: 'rgba(54, 56, 64, 1)',
  outlinedPrimaryButtonHoverBG: '#F8CC82',
  outlinedPrimaryButtonHoverColor: '#333333',
  outlinedSecondaryButtonHoverBG: '#FCFCFC',
  outlinedSecondaryButtonHoverColor: '#333333',
  containedSecondaryButtonHoverBG: 'rgba(90, 187, 255, 0.5)',
  graphStrokeColor: 'rgba(37, 52, 73, .2)',
}

declare module '@mui/material/styles' {
  interface ThemeOptions {
    themeName?: string // optional
  }
}

const theme = {
  palette: {
    primary: {
      main: '#08090D',
    },
    secondary: {
      main: '#F200FF',
    },
    background: {
      default: '#08090D',
    },
    info: {
      main: '#3C00FF',
    },
  },
  typography: {
    fontFamily: [
      'HelveticaNeue',
      'HelveticaNeue-Bold',
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        containedPrimary: {
          color: darkTheme.fontColor,
          backgroundImage: darkTheme.primaryButtonBG,
          '&:hover': {
            backgroundColor: darkTheme.primaryButtonHoverBG,
          },
          '@media (hover:none)': {
            color: '#FCFCFC',
            backgroundColor: `${darkTheme.primaryButtonBG} !important`,
            '&:hover': {
              backgroundColor: `${darkTheme.primaryButtonHoverBG} !important`,
            },
          },
        },
        containedSecondary: {
          color: '#FCFCFC',
          backgroundColor: darkTheme.containedSecondaryButtonBG,
          '&:hover': {
            color: '#FCFCFC',
            backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
          },
          '@media (hover:none)': {
            color: '#FCFCFC',
            // backgroundColor: darkTheme.paperBg,
            '&:hover': {
              color: '#FCFCFC',
              backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
            },
          },
        },
        outlinedPrimary: {
          color: darkTheme.primaryButtonBG,
          borderColor: darkTheme.primaryButtonBG,
          '&:hover': {
            color: darkTheme.gold,
            // backgroundColor: darkTheme.primaryButtonHoverBG,
            borderColor: darkTheme.primaryButtonBG,
          },
          '@media (hover:none)': {
            color: darkTheme.primaryButtonBG,
            borderColor: darkTheme.primaryButtonBG,
            '&:hover': {
              color: `${darkTheme.gold} !important`,
              backgroundColor: `${darkTheme.primaryButtonBG} !important`,
            },
          },
        },
        outlinedSecondary: {
          color: darkTheme.color,
          borderColor: darkTheme.color,
          '&:hover': {
            color: darkTheme.outlinedSecondaryButtonHoverColor,
            backgroundColor: darkTheme.outlinedSecondaryButtonHoverBG,
            borderColor: '#333333',
          },
        },
        textPrimary: {
          color: darkTheme.gray,
          '&:hover': {
            color: darkTheme.textHighlightColor,
            backgroundColor: '#00000000',
          },
          '&:active': {
            color: darkTheme.gold,
            borderBottom: '#F8CC82',
          },
        },
        textSecondary: {
          color: darkTheme.color,
          '&:hover': {
            color: darkTheme.textHighlightColor,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: '#0D0929',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontFamily: 'HelveticaNeue-Bold, HelveticaNeue',
          border: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#1D1A34',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: '#1D1A34',
          color: '#fff',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#2E2B46',
        },
      },
    },
  },
}

export default createTheme(theme)
