import React, { FC } from 'react'
import Home from './views/Home/Home'
import Main from './views/Main/Main'
import { useRoutes } from 'react-router-dom'
import IDO from './views/IDO/IDO'
import Events from './views/Events/Events'
import PersonalCenter from './views/PersonCenter/PersonalCenter'

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  /*   {
    path: '/main',
    element: <Main />,
    children: [
      { path: 'ido', element: <IDO /> },
      { path: 'events', element: <Events /> },
      { path: 'personal_center', element: <PersonalCenter /> },
    ],
  }, */
]

const AppRouter: FC = () => {
  const element = useRoutes(routes)
  return element
}
export default AppRouter
